import React from "react"
import { Layout } from "@components/layout"
import { Breadcrumbs } from "@components/shared"
import PostItem from "./PostItem"
import { Contact } from "@components/standard"

const SingleCategory = ({ pageContext }) => {
  const { posts, page } = pageContext
  return (
    <Layout seo={page.seo} siteMetaData={page.siteMetaData}>
      <Breadcrumbs
        elements={[
          {
            label: "Blog",
            url: `/blog/`,
          },
          {
            label: page.name,
            url: `/blog/categories/${page.slug}/`,
          },
        ]}
      />
      {posts.map((el, i) => {
        return <PostItem {...el} index={i} />
      })}
      <Contact />
    </Layout>
  )
}

export default SingleCategory
