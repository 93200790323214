import { withHtml } from '@components/logic';
import { AnimatedLink } from '@components/shared';
import React from 'react';

import ArrowSVG from './icons/chevron_right.svg';
import { truncateString } from './logic';
import {
    container__post,
    container__post__content,
    container__post__arrow,
    container__post__tags,
} from './styles/blog.module.scss';

const Title = withHtml('h4');
const Content = withHtml('p');

const PostItem = ({ title, slug, content, featuredImage, tags }) => {
    console.log('c', content)
    let clean = content.replace(/<\/?[^>]+(>|$)/g, '');
    return (
        <AnimatedLink className={container__post} to={`/blog/${slug}`}>
            {/* <img src={featuredImage.node.sourceUrl} alt=""></img> */}
            <div className={container__post__content}>
                <Title>{title}</Title>
                <Content>{truncateString(clean, 150)}</Content>
                {tags ? (
                    <div className={container__post__tags}>
                        {tags.nodes.map((el) => (
                            <AnimatedLink to={`/tags/${el.slug}`}>
                                #{el.name}
                            </AnimatedLink>
                        ))}
                    </div>
                ) : null}
            </div>
            <div className={container__post__arrow}>
                <img
                    style={{
                        width: '25px',
                        height: '22px',
                        minHeight: 'unset',
                    }}
                    src={ArrowSVG}
                    alt="arrow"></img>
            </div>
        </AnimatedLink>
    );
};
export default PostItem;
